import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Gallery = (props) => {
  const edges = props?.data.allInstaNode.edges;

  const toDateString = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const prefix = x => (x < 10 ? `0${x}` : x);
    return `${prefix(date.getDate())}.${prefix(date.getMonth() + 1)}.${date.getFullYear()}`;
  };

  return (
    <Layout bodyClass="page-gallery">
      <SEO title="Galerie" />
      <div className="intro intro-small">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Galerie</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-3 pb-3 pt-md-3">
        <div className="row d-flex flex-wrap justify-content-around">
          {edges.map(({ node }) => (
              <div key={node.id} className="card card-lifted mb-3 mb-md-8" style={{ width: 'min-content' }}>
                  <div className="card-content">
                    <a key={node.id} href={`https://www.instagram.com/p/${node.id}/`} target="_blank" rel="noopener noreferrer">
                      <Img className="card-image" fixed={node.localFile.childImageSharp.fixed} />
                    </a>
                    <p className="mt-2">{node.caption}</p>
                  </div>
              </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

/*
  <a key={node.id} href={`https://www.instagram.com/p/${node.id}/`} target="_blank" rel="noopener noreferrer">
    <div className="overlay-container">
      <Img className="card-image" fixed={node.localFile.childImageSharp.fixed} />
      <div className="overlay">
        <span>{ toDateString(node.timestamp) }</span>
        <div className="ig-likes">{ node.likes }</div>
      </div>
    </div>
  </a>
*/

export const query = graphql`
  query GalleryQuery {
    allInstaNode(sort: {fields: timestamp, order: DESC}) {
      edges {
        node {
          id
          likes
          caption
          localFile {
            childImageSharp {
              fixed(quality: 100, width: 300, height: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          timestamp
        }
      }
    }
  }
`;

export default Gallery;
